.section-bg {
    background: #252734;
}
.pos-relative {
    position: relative;
}
.overflow-hidden {
    overflow: hidden!important;
}
.footer-inner-shape-top-left {
    top: -575px;
    left: -490px;
}
.footer-inner-shape-top-left {
    visibility: visible;
    opacity: 1;
    top: -675px;
    left: -590px;
}
.footer-inner-shape-top-left {
    position: absolute;
    width: 800px;
    height: 800px;
    border: 1px solid rgba(255,255,255,.1);
    border-radius: 50%;
    top: -475px;
    left: -390px;
    visibility: hidden;
    opacity: 0;
}
.footer-inner-shape-top-left::before {
    position: absolute;
    content: "";
    width: 935px;
    height: 935px;
    border: 1px solid rgba(255,255,255,.1);
    border-radius: 50%;
    top: -70px;
    left: -70px;
}
.footer-inner-shape-top-left::after {
    position: absolute;
    content: "";
    width: 1065px;
    height: 1065px;
    border: 1px solid rgba(255,255,255,.1);
    border-radius: 50%;
    top: -140px;
    left: -140px;
}
.footer-inner-shape-top-right {
    top: -575px;
    right: -490px;
}
.footer-inner-shape-top-right {
    visibility: visible;
    opacity: 1;
    top: -675px;
    right: -590px;
}
.footer-inner-shape-top-right {
    position: absolute;
    width: 800px;
    height: 800px;
    border: 1px solid rgba(255,255,255,.1);
    border-radius: 50%;
    top: -475px;
    right: -390px;
    visibility: hidden;
    opacity: 0;
}
.footer-inner-shape-top-right::before {
    position: absolute;
    content: "";
    width: 935px;
    height: 935px;
    border: 1px solid rgba(255,255,255,.1);
    border-radius: 50%;
    top: -70px;
    right: -70px;
}
.footer-inner-shape-top-right::after {
    position: absolute;
    content: "";
    width: 1065px;
    height: 1065px;
    border: 1px solid rgba(255,255,255,.1);
    border-radius: 50%;
    top: -140px;
    right: -140px;
}
.section-gap-t-165 {
    padding-top: 135px;
}
.section-content {
    margin-bottom: 100px;
}
.section-tag {
    font-size: 18px;
    font-weight: 300;
    position: relative;
    display: inline-block;
    padding-left: 27px;
}
.section-tag::before {
    position: absolute;
    content: "||";
    top: 0;
    left: 0;
}
.section-title {
    font-size: 70px;
}
.section-gap-tb-165 {
    padding-top: 135px;
    padding-bottom: 135px;
}
.mb-n5 {
    margin-bottom: -3rem;
}
.align-items-center {
    align-items: center!important;
}
.justify-content-between {
    justify-content: space-between!important;
}
.footer-single-info .info-box .text {
    font-size: 32px;
}
.social-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: -20px;
}    
.social-link li {
    margin-right: 20px;
    margin-bottom: 20px;
}
.social-link a {
    display: block;
    color: #fff;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,.1);
    line-height: 45px;
    text-align: center;
}
.footer-bottom {
    border-top: 1px solid rgba(255,255,255,.1);
    padding-top: 20px;
    padding-bottom: 20px;
}
.col-auto {
    flex: 0 0 auto;
    width: auto;
}
.footer-single-info .info-box .text {
    display: inline-block;
    color: #fff;
    font-size: 32px;
    font-weight: 300;
}
.footer-single-info .info-box .icon {
    width: 85px;
    height: 85px;
}
.footer-single-info .info-box .icon {
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,.1);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    margin-right: 20px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}
.light-icon{
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(335deg) brightness(109%) contrast(101%);
}
span.icon img {
    width: 21px;
}
p.copyright-text {
    margin: 0;
}
.copyright-text-samplework{
    width:100%;
    text-align:center;
}
@media (max-width:767px) {
    .footer-single-info .info-box .text {
        display: inline-block;
        color: #fff;
        font-size: 20px;
        font-weight: 300;
    }
    .section-gap-tb-165 {
        padding-top: 65px;
        padding-bottom: 55px;
    }
    .footer-section {
        padding: 0px 20px;
    }
    .footer-single-info .info-box .icon {
        width: 55px;
        height: 55px;
    }
    .footer-single-info a {
        display: flex;
        align-items: center;
    }
    
}
