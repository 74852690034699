header.header {
    background: transparent;
    display: block;
    width: 100%;
    position: fixed;
    z-index: 3;
    transition: 0.4s;
}
.inner-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.nav_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logo_header h1 {
    color: #fff;
}
.logo_header h1 a {
    color: #fff;
    text-decoration: none;
    font-size: 31px;
    transition: all ease 0.4s;
}
.lencer-btn{
    padding-left: 40px;
    padding-right: 40px;
    height: 65px;
    border-radius: 32.5px;
    line-height: 60px;  
    text-align: center;
    position: relative;
    z-index: 9;
    overflow: hidden;
    color: #fff;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    outline: 0!important;
    text-decoration: none;
    border: 1px solid #3b3d49;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    display: block;
}
.logo_header {
    padding: 30px 0px;
}
.header-button-icon {
    width: 16px;
    margin-left: 11px;
}
.lencer-btn{
    display: flex;
    align-items: center;
    transition: all ease 0.4s;
}
.header.is-sticky .lencer-btn{
    height: 50px;
    transition: all ease 0.4s;
}
.lencer-btn:hover {
    color: #fff;
    
}
.btn{
    border: none;
}
.header.is-sticky {
    background: #252734;
}
.header.is-sticky .logo_header h1 a {
    font-size: 21px;
}
.header.is-sticky .logo_header {
    padding: 15px;
    transition: all ease 0.4s;
}
@media(max-width:767px){
    .header .logo_header h1 a {
        font-size: 21px;
    }
    .header .logo_header {
        padding: 15px;
        transition: all ease 0.4s;
    }
    .header .lencer-btn {
        height: 50px;
        transition: all ease 0.4s;
        padding: 10px 20px;
    }
  }