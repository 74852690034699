section.breadcrumb {
    /* background: linear-gradient( 158deg , rgb(48, 39, 120) 0%, rgb(16, 6, 79) 100%); */
    background-color: #252734;
    margin-bottom: 50px;
    padding: 140px 90px 60px 90px;
    height: 360px;
    text-align: center;
    position: relative;
}
.breadcrumb-lines {
    position: absolute;
    width: 320px;
    height: 320px;
    background: transparent;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,.1);
}
.breadcrumb-left {
    left: -180px;
    top: -180px;
}
.breadcrumb-lines::after {
    position: absolute;
    content: "";
    width: 600px;
    height: 600px;
    background: transparent;
    border-radius: 50%;
    left: -130px;
    bottom: -150px;
    border: 1px solid rgba(255,255,255,.1);
}
.breadcrumb-right {
    right: -180px;
    bottom: -180px;
}
.inner-breadcrum h1 {
    color: #fff;
    font-size: 50px;
    margin: 0 0 10px 0;
}
.inner-breadcrum p {
    color: #fff;
}
section.breadcrumb {
    overflow: hidden;
}
@media (max-width:767px) {
    section.breadcrumb {
        padding: 118px 11px;
    }
    .inner-breadcrum h1 {
        font-size: 38px;
    }
}