.project-box-single-item .img-box {
    padding: 100px 100px 0 100px;
    height: 548px;
}
.project-box-single-item .img-box .bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(37,39,52,.95);
}
.project-box-single-item .img-box .bg-image {
    position: absolute;
    -webkit-transform: translate(-50%,-50%) rotate(-45deg);
    transform: translate(-50%,-50%) rotate(-45deg);
    top: 50%;
    left: 50%;
    z-index: -1;
}
.project-box-single-item .img-box .image {
    position: relative;
    z-index: 1;
}
.project-box-single-item .img-box .image img {
    width: 100%;
    transition: all ease 12s;
}
.image:hover img {
    transition: all ease 12s;
}
.project-box-single-item .title {
    font-size: 28px;
    margin-top: 55px;
    margin-bottom: 25px;
    text-align: center;
    padding: 0px 100px;
}
.project-box-single-item .title {
    font-family: Poppins,sans-serif;
    font-weight: 500;
}
.project-box-single-item .title a {
    color: #fff;
}
.project-box-single-item .catagory-nav-item {
    font-weight: 300;
}
.project-box-single-item .catagory-nav-item li {
    display: inline-block;
    position: relative;
    margin-right: 10px;
}
.project-box-single-item .img-box {
    overflow: hidden;
    position: relative;
    z-index: 1;
}
div#projectcard1 .image:hover img{
    transform: translateY(-79%);
}
div#projectcard2 .image:hover img{
    transform: translateY(-79%);
}
div#projectcard3 .image:hover img{
    transform: translateY(-83%);
}
div#projectcard4 .image:hover img{
	transform: translateY(-84.5%);
}
div#projectcard5 .image:hover img{
    transform: translateY(-78%);
}
div#projectcard6 .image:hover img{
    transform: translateY(-81%);
}
div#projectcard7 .image:hover img{
    transform: translateY(-81%);
}
div#projectcard8 .image:hover img{
    transform: translateY(-78.5%);
}
.image img{
    cursor: pointer;
}
@media (max-width:767px) {
    .project-box-single-item .img-box {
        padding: 60px 30px 0 30px;
        height: 548px;
    }
    div#projectcard1 .image:hover img {
        transform: translateY(-66%);
    }
    div#projectcard2 .image:hover img {
        transform: translateY(-66.5%);
    }
    div#projectcard3 .image:hover img {
        transform: translateY(-73%);
    }
    div#projectcard4 .image:hover img {
        transform: translateY(-75%);
    }
    div#projectcard5 .image:hover img {
        transform: translateY(-65%);
    }
    div#projectcard6 .image:hover img {
        transform: translateY(-69.5%);
    }
    div#projectcard7 .image:hover img {
        transform: translateY(-69%);
    }
    div#projectcard8 .image:hover img {
        transform: translateY(-65.5%);
    }
    .project-box-single-item .title {
        font-size: 22px;
        margin-top: 26px;
        margin-bottom: 25px;
        padding: 0px 30px;
    }

}