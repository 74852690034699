*{
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body{
  overflow-x: hidden;
  background: #2a2c39;
  color: #858792;
  font-family: Poppins,sans-serif;
}

.append-button, .btn, .btn:focus, .swiper-button-next, .swiper-button-prev, .swiper-pagination-bullet, .swiper-slide, a, a:active, a:focus, a:hover, button, button:focus, input, input:focus, select, textarea {
  text-decoration: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.container {
  width: 1280px;
  margin: 0 auto;
}

@media(max-width:767px){
  .container {
    width: 100%;
    padding: 0px 10px;
  }
}